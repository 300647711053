
import { defineComponent, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "settlement-offer-form-component",
  components: { Form, Field, ErrorMessage },
  props: {
    claim: Object as any,
    details: Object as any,
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);

    const addCommaToNumberString = (amount) => {
      return variables.addCommaToNumberString(amount);
    };

    const addCommaToNumber = (event) => {
      if (event?.target?.id) {
        return variables.numberFormatter(event.target.id);
      }
    };

    const payload = ref({
      offerDocument: "",
      // proofOfPayment: "",
      repairAmount: props.details?.amount || "",
      // repairAmount: addCommaToNumberString(props.details?.amount) || "",
      amount: "",
      additionalComments: "",
    });

    const selectDocument = (event, type) => {
      payload.value[type] = event.target.files[0];
    };

    const saveSettlementOffer = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const form = document.getElementById(
              props.details?.uuid
            ) as HTMLFormElement;
            const formData = new FormData(form);
            formData.append("claimPublicId", props?.details?.publicId);
            formData.append("title", props?.details?.title);
            formData.append("settlementType", props?.details?.modelName);
            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post("/settlement", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                // variables.toastAlert(response.data.data.message, "success");
                variables.toastAlert(
                  "Settlement offer has been sent",
                  "success"
                );
                resetForm();
                // // Redirect back to claims history page
                // router.push({
                //   name: "underwriter-client-claims-listing",
                // });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    return {
      addCommaToNumberString,
      addCommaToNumber,
      saveSettlementOffer,
      selectDocument,
      payload,
      submitButton1,
    };
  },
});
